<footer
  elTestId="K4XuOGnCpAV6VDJ5a9wbR"
  class="o-footer -minimal"
>
  <hr
    elTestId="Zu6s7MPW49SqqmTsQms3p"
    class="a-divider"
  />
  <div
    elTestId="rzospDRrhHMP8AKp4zFrs"
    class="e-container"
  >
    <div
      elTestId="3Ga-_6Cgv6VBRMSuyRi_h"
      class="o-footer__bottom"
    >
      <ul
        elTestId="yHHbQu7Ep2it64ZBimrZ2"
        class="o-footer__links"
      >
        <li elTestId="QKxuLeLeAXVHRC87jT7jz">
          <div
            elTestId="PonLFeywgbP6UZIBNceQP"
            class="a-link a-link--integrated"
          >
            <a
              elTestId="hEWgSjG1qG_Qg8Usd8y4G"
              [attr.aria-label]="'common.imprint' | translate"
              href="{{
                (content$ | async)?.legalInfo?.corporateInformationUrl
              }}"
              target="_blank"
              rel="noopener"
            >
              <span elTestId="EnsiRHvCax93TYBU8XWDF">{{
                  'common.imprint' | translate
                }}</span>
            </a>
          </div>
        </li>
        <li elTestId="zDE0gEFc_Mq0ExJxymucU">
          <div
            elTestId="DzTRTnsnSG1WJEtzD-keD"
            class="a-link a-link--integrated"
          >
            <a
              elTestId="DV1i5LwoXGXO_vndTz-bQ"
              [attr.aria-label]="'common.dataProtection' | translate"
              href="{{ (content$ | async)?.legalInfo?.legalNoticeUrl }}"
              target="_blank"
              rel="noopener"
            >
            <span elTestId="XAP6rXyDUdxTLQj5Ju5gQ">{{
                'common.dataProtection' | translate
              }}</span>
            </a>
          </div>
        </li>
        <li elTestId="FfEaARS4kkJQExEyU9cfo">
          <div
            elTestId="JBeR53peEbksOCkYs2G4a"
            class="a-link a-link--integrated"
          >
            <a
              elTestId="i1fiG4t3Mz3Um0ZX0e0t7"
              [attr.aria-label]="'common.termsOfUse' | translate"
              href="{{ (content$ | async)?.legalInfo?.termsOfUseUrl }}"
              target="_blank"
              rel="noopener"
            >
              <span elTestId="AOiJxXnqG2kAmx8PLqd_2">{{
                  'common.termsOfUse' | translate
                }}</span>
            </a>
          </div>
        </li>
        <li elTestId="EqX92sBRUBqRDUPiqE93m">
          <div
            elTestId="MTgYodawTExoRwcj38xMj"
            class="a-link a-link--integrated"
          >
            <a
              elTestId="7ONqmIltbTbqBNNNgu1bC"
              [attr.aria-label]="'common.privacySettings' | translate"
              (click)="showPrivacy()"
              rel="noopener"
            >
              <span elTestId="jETe6JHODBfmNhajWiAM3">{{
                  'common.privacySettings' | translate
                }}</span>
            </a>
          </div>
        </li>
      </ul>
      <hr
        elTestId="NJUKtkdiIZBV2_dwC9Aap"
        class="a-divider"
      />
      <div
        elTestId="iU1sPPzABOb4ejLZDhzQi"
        class="o-footer__copyright"
      >
        <em
          elTestId="dcF6XE7US1IDzZGIOB5ek"
          class="a-icon boschicon-bosch-ic-copyright-frame"
          title="Copyright"
        ></em>
        {{ (content$ | async)?.legalInfo?.copyright }}
      </div>
    </div>
  </div>
</footer>
<dock-privacy-settings
  id="privacy-settings"
  link-url-marketing="/privacy-statement"
  link-url-comfort="/privacy-statement"
  link-url-imprint="https://www.bosch.com.sg/corporate-information/?prevent-auto-open-privacy-settings=1"
  link-url-policy="/privacy-statement"
>
</dock-privacy-settings>
