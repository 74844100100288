import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CONSTANTS } from '../../constant';
import { DocumentSearchingResult } from '../models/document-searching-result.model';
import { Observable, from, switchMap } from 'rxjs';
import { convertFileToBase64 } from '../utils/file.utils';
import { DocumentMetadata } from '../models/document-metadata.model';
import { DocumentMetadataRequest } from '../models/upload-document-metadata-request.model';

@Injectable({
  providedIn: 'root',
})
export class DocumentApiService {
  constructor(private http: HttpClient) {}

  searchDocument(searchPayload: any, pageIndex?: number, pageSize?: number) {
    const pageElement = `?pageIndex=${pageIndex}&pageSize=${pageSize}`;
    return this.http.put<DocumentSearchingResult>(
      `${CONSTANTS.API.SEARCH_DOCUMENT}${pageElement}`,
      searchPayload
    );
  }

  uploadDocuments(files: File[]): Observable<any> {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file);
    });
    return this.http.post<any>(CONSTANTS.API.UPLOAD_DOCUMENT, formData);
  }

  openResource(documentURL: string) {
    let url = CONSTANTS.API.VIEW_DOCUMENT.replace('<filePath>', documentURL);
    return this.http.get(url, { responseType: 'blob' });
  }

  getDocumentMetaData() {
    return this.http.get<DocumentMetadata[]>(CONSTANTS.API.GET_METADATA);
  }

  uploadDocumentMetadata(request: DocumentMetadataRequest) {
    return this.http.put<any>(CONSTANTS.API.UPDATE_METADATA, request);
  }

  deleteDocument(filePaths: string[]) {
    if (!filePaths || filePaths.length === 0) {
      return;
    }
    const url = `${CONSTANTS.API.SEARCH_DOCUMENT}?filePaths=${filePaths.join(',')}`;
    return this.http.delete<any>(url);
  }

  replaceDocument(filePath: string, newFile: File) {
    const formData = new FormData();
    formData.append('newFile', newFile);

    return this.http.post<any>(
      `${CONSTANTS.API.REPLACE_DOCUMENT}?oldFilePath=${filePath}`,
      formData
    );
  }
}
